import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import PlusSolidIcon from '../images/icons/plus-solid.svg';

const PopupContent = (props) => {
  const { content, url } = props;


  useEffect(() => {

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {


    if (url && !content) { 
      window.location.href = url; 
    } else if (!content) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
    } else {
      setIsModalVisible(true);
    }

    
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick = (event) => {
  

    
    if (event.target.classList.contains('programlink')) {
      setIsModalVisible(false);
    }
  };


  return (
    <>
      <a onClick={showModal} class="btn btn-theme effect btn-sm uni-read-more-plus-btn"><span><img src={PlusSolidIcon} alt="+" className="plusicon" /></span></a>

      <Modal
        className="antd-modal-main"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        footer={null}
      >
        <div className="newStyle popup-content-container">{ReactHtmlParser(content)}</div>
      </Modal>
    </>
  );
};

export default PopupContent;
