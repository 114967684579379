import React from 'react'
import ArrowRightBlueIcon from '../images/icons/arrow-right-solid-blue.svg';

const WorldgradJourneyPG = (props) => {
    const {uniData} = props
    return (
        <div class="rd3">

            {uniData.acf.worldgrad_journey_for_postgraduate != null   && (uniData.acf.worldgrad_journey_for_postgraduate.year_1_trem_2_program_provider == null || uniData.acf.worldgrad_journey_for_postgraduate.year_1_trem_2_program_provider === "") ? (
                <div className="row justify-content-center">
                <div class="col-12 col-lg-6 col-sm-6 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn" data-wow-delay="0.2s" >
                <div class="process-step-item margin-40px-bottom">
                  <div class="process-step-item-box">
                    <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                    <div class="process-step-icon">
                      <span class="process-step-number text-white font-weight-500 br-15">
                        <span class="process-step-number-bfr bg-transparent-sky-blue "></span>
                        <span class="process-step-year">{uniData.acf.worldgrad_journey_for_postgraduate.year_one_program.split(/:|-/)[0]}</span>
                        <span class="process-step-number-afr bg-fast-blue"></span>
                      </span>
                    </div>
                    <div class="process-content last-paragraph-no-margin">
                    <a href={uniData.acf.worldgrad_journey_for_postgraduate.year_one_program_link}><span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">{uniData.acf.worldgrad_journey_for_postgraduate.year_one_program.split(/[:|-](.*)/)[1]}</span></a>
                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_one_program_provider }} ></p>
                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_one_credit }} ></p>
                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_1_study_mode }} ></p>
                    </div>
                  </div>
                </div>
                <img src={ArrowRightBlueIcon} alt="->" className="left-icon process-icon-next2 new-process-icon-next2" />
              </div>
              <div class="col-12 col-lg-6 col-sm-6 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn" data-wow-delay="0.3s" >
                <div class="process-step-item">
                  <div class="process-step-item-box">
                    <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                    <div class="process-step-icon">
                      <span class="process-step-number text-white font-weight-500 br-15">
                        <span class="process-step-number-bfr bg-transparent-sky-blue "></span>
                        <span class="process-step-year">{uniData.acf.worldgrad_journey_for_postgraduate.year_two_program.split(/:|-/)[0]}</span>
                        <span class="process-step-number-afr bg-fast-blue"></span>
                      </span>
                    </div>
                    <div class="process-content last-paragraph-no-margin">
                      <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom"> {uniData.acf.worldgrad_journey_for_postgraduate.year_two_program.split(/:|-/)[1]}</span>
                      
                      <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_ }} ></p>
                      <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_credit }} ></p>
                      <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_study_mode }} ></p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
                         
                         
                    ):
                    (
                        <div className="row justify-content-center">
                             <div class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn" data-wow-delay="0.2s" >
                        <div class="process-step-item margin-40px-bottom">
                            <div class="process-step-item-box">
                                <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15" ></span>
                                <div class="process-step-icon">
                                    <span class="process-step-number text-white font-weight-500 br-15">
                                    <span class="process-step-number-bfr bg-transparent-sky-blue "></span><span class="process-step-year">
                                        {uniData.acf.worldgrad_journey_for_postgraduate.year_one_program.split(/:|-/)[0]}
                                        </span> {}
                                         <span class="process-step-number-afr bg-fast-blue"></span>
                                    </span>
                                </div>
                                <div class="process-content last-paragraph-no-margin">
                                    <a href={uniData.acf.worldgrad_journey_for_postgraduate.year_one_program_link}><span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">{uniData.acf.worldgrad_journey_for_postgraduate.year_one_program.split(/[:|-](.*)/)[1]}</span></a>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_one_program_provider }} ></p>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_one_credit }} ></p>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_1_study_mode }} ></p>
                                </div>                        
                            </div>
                        </div>
                       <img src={ArrowRightBlueIcon} alt="->" className="left-icon process-icon-next new-process-icon-next" />
                    </div>

					<div class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn" data-wow-delay="0.3s" >
                        <div class="process-step-item process-step-item-p40 margin-40px-bottom">
                            <div class="process-step-item-box">
                                <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                                <div class="process-step-icon">
                                    <span class="process-step-number text-white font-weight-500 br-15">
										
                                        <span class="process-step-number-bfr bg-transparent-sky-blue "></span><span class="process-step-year">{uniData.acf.worldgrad_journey_for_postgraduate.year_1_term_2.split(/:|-/)[0]}</span> <span class="process-step-number-afr bg-fast-blue"></span>
                                    </span>
                                </div>
                                <div class="process-content last-paragraph-no-margin">
                                    <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">{uniData.acf.worldgrad_journey_for_postgraduate.year_1_term_2.split(/[:|-](.*)/)[1]}</span>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_1_trem_2_program_provider }} ></p>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_1_term_2_credit }} ></p>
                                  	<p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_1_term_2_study_mode }} ></p>
                                </div>                        
                            </div>
                        </div>
                        <img src={ArrowRightBlueIcon} alt="->" className="left-icon process-icon-next2 new-process-icon-next2" />
                    </div>
					<div class="col-12 col-lg-4 col-sm-4 text-center process-step-style-03 simple md-margin-2-rem-bottom wow animate__fadeIn" data-wow-delay="0.3s" >
                        <div class="process-step-item">
                            <div class="process-step-item-box">
                                <span class="process-step-item-box-bfr bg-extra-medium-gray  br-15"></span>
                                <div class="process-step-icon">
                                    <span class="process-step-number text-white font-weight-500 br-15">
										
                                        <span class="process-step-number-bfr bg-transparent-sky-blue "></span><span class="process-step-year">{uniData.acf.worldgrad_journey_for_postgraduate.year_two_program.split(/:|-/)[0]}</span> <span class="process-step-number-afr bg-fast-blue"></span>
                                    </span>
                                </div>
                                <div class="process-content last-paragraph-no-margin">
                                    <span class="alt-font d-inline-block font-weight-500 text-medium-slate-blue text-uppercase margin-10px-bottom">{uniData.acf.worldgrad_journey_for_postgraduate.year_two_program.split(/[:|-](.*)/)[1]}</span>
									                  <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_ }} ></p>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_credit }} ></p>
                                    <p class="margin-10px-bottom" dangerouslySetInnerHTML={{ __html: uniData.acf.worldgrad_journey_for_postgraduate.year_two_study_mode }} ></p>
                                </div>                        
                            </div>
                        </div>
                    </div>
                    
              
                   
                        </div>
                    )
                    }


        
      </div>
           
    )
}

export default WorldgradJourneyPG
